import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Carousel from 'nuka-carousel'

import Layout from '../components/layout'
import Head from '../components/head'


// import { staticQuery } from '../constants/indexQuery'
// import { galleryGenerate, document } from '../components/galleryPictures'

import { 
  document,
  galleryItemArray,
 } from '../constants/locationTemplate'

import bodyStyles from '../sass/components/body.module.scss'
import locationStyles from '../sass/pages/location.module.scss'
import homeStyles from '../sass/pages/home.module.scss'
import commonStyles from '../sass/components/common.module.scss'
import buttonStyles from '../sass/components/button.module.scss'
import carouselGalleryStyles from '../sass/components/carouselGalleryBoxes.module.scss'

import houseIconSvg from '../assets/icons/vcd-house-color.svg'
import swimminPoolIconSvg from '../assets/icons/vcd-swimming-pool-color.svg'
import sunIconSvg from '../assets/icons/vcd-sun-color.svg'
import umbrellaIconSvg from '../assets/icons/vcd-umbrella-color.svg'
// import CartIcon from '../assets/icons/cart.svg.js'
// import cl from "../utils/cl"

import { 
  sunIconAlt,
  houseIconAlt,
  umbrellaIconAlt,
  swimmingPoolIconAlt
} from '../constants/iconAlt'

const bS = bodyStyles
const btS = buttonStyles
const cgS = carouselGalleryStyles
const lS = locationStyles
const hoS = homeStyles
const cmS = commonStyles


// Component ////////////////////////////////::
const IndexPage = (props) => {

  // IMPROVEMENT : try externalize the graphql query
// export const query = graphql`${queryLocation}` // Doesnt work 
// NO, put it at the end of the file ==> This line reminds the graphql static? query is run from an external file

const query = useStaticQuery(staticQuery)

  const data = query.wpgraphql.accueilBy.accueilAcf

  let galleryOptions = []

  galleryItemArray.forEach((galleryItem, index) => {
    galleryOptions[index] = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: () => {
          const picture = data[galleryItem] ? data[galleryItem] : null
          if (data[galleryItem]) {
            const alt = picture.altText
            const imageurl = picture.sourceUrl
            return <img className={cgS.boxOption} alt={alt} src={imageurl} />
          }            
        },
      },
    }
  })

{/* galleryOptions = galleryGenerate() */} 

  return (
    <Layout props pathCustom={props.location.pathname} >
      <Head title={'Home'} />
      <div className={bS.container}>
        <div className={cmS.titleWrapper}></div>
        {/*<CartIcon />*/}

          <h1 className={cmS.title}> Vacances en Provence </h1>
          <div className={cmS.iconWrapperWrapper}>
          <div className={cmS.iconWrapper}>
            <img className={cmS.box3Icon} src={sunIconSvg} alt={sunIconAlt} />
            <img className={cmS.box4Icon} src={umbrellaIconSvg} alt={umbrellaIconAlt} />
            <img className={cmS.box1Icon} src={swimminPoolIconSvg} alt={swimmingPoolIconAlt} />            
            <img className={cmS.box2Icon} src={houseIconSvg} alt={houseIconAlt} />
          </div>
        </div>


        {/* Section 1 : Title and main image */}
        <div className={cmS.mainComponentWrapperPhotoAlbum}>
          <div className={cgS.carouselVisible}>
            <div className={cmS.mainImageWrapper}>
              <div className={cmS.mainImage}>
                <Carousel
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      onClick={previousSlide}
                      className={btS.buttonControlLeftCarousel}
                    >
                      {'<<'}
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      onClick={nextSlide}
                      className={btS.buttonControlRightCarousel}
                    >
                      {'>>'}
                    </button>
                  )}
                  renderBottomCenterControls={({ currentSlide }) => null}
                  // withoutControls
                  autoplay={true}
                  // autoplayReverse={true}
                  pauseOnHover={false}
                  // slideIndex={7}
                  slidesToShow={1}
                  // autoGenerateStyleTag={false}
                  transitionMode={'scroll'}
                  width="100%"
                  wrapAround={true}
                >      
                  {galleryOptions.map(galleryOption =>
                    documentToReactComponents(document, galleryOption)
                  )}
                </Carousel>
              </div>
            </div>
          </div>

          <div className={cgS.mainImageVisible}>
            <div className={cmS.mainImageWrapper}>
              <div className={cmS.mainImage}>
                <Carousel
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      onClick={previousSlide}
                      className={btS.buttonControlLeftCarousel}
                    >
                      {'<<'}
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      onClick={nextSlide}
                      className={btS.buttonControlRightCarousel}
                    >
                      {'>>'}
                    </button>
                  )}
                  renderBottomCenterControls={({ currentSlide }) => null}
                  // withoutControls
                  autoplay={true}
                  // autoplayReverse={true}
                  pauseOnHover={false}
                  // slideIndex={7}
                  slidesToShow={2}
                  // autoGenerateStyleTag={false}
                  transitionMode={'scroll'}
                  width="100%"
                  wrapAround={true}
                >                  
                  {galleryOptions.map(galleryOption =>
                    documentToReactComponents(document, galleryOption)
                  )}
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        
        <div className={cmS.mainComponentWrapper}>
          <h1 className={cmS.subtitle}>Bienvenue à Salernes.</h1>
          <div className={hoS.paragraphWrapper}>
          <div className={hoS.paragraphBoxWrapper}>
            <div className={hoS.paragraphBox}>
              <div className={cmS.paragraph}>
                <p>
                  Varcamdobb vous accueille à Salernes 🌞 depuis bientôt 21 ans !
                  <br />
                  <br />
                  Ce petit coin de Provence continue de faire rêver, été après
                  été... les charmes et les couleurs conjugués du Verdon et de
                  la nature préservée des parcs régionaux 🏞️, les champs de lavande
                  (celui des cigales fait évidemment partie du tableau).
                  <br />
                  <br />
                  La Provence, c'est aussi les petits marchés, les villages dont on tombe amoureux tels Cotignac et Tourtour, sans oublier que Saint-Tropez et notre Venise-en-Provence, Port Grimaud, sont à 1h de route, traversant au passage pinèdes et vignes !
                  <br />
                  <br />
                  Biensûr il y a les apéros et les barbecues, le meilleur rosé 🍷et les olives, et les inoubliables sessions piscine 🤽 : c'est pour
                  tout cela et bien plus que l'on y revient.
                </p>
              </div>
            </div>
            </div>
            <div className={hoS.paragraphBoxWrapper}>
            <div className={hoS.paragraphBox}>
              <div className={cmS.paragraph}>
                <p>
                  Visitez notre page de contact en <Link className={hoS.email} to="/contact">                    
                    cliquant ici !
                  </Link>
                  <br />
                  <br />
                  Découvrez ou retrouvez cet interlocuteur (et son léger accent 😉) connaissant
                  par coeur les Gorges du Verdon, les petits marchés, la faune
                  et la flore, tout comme chacune de ses villas, et est toujours
                  prêt à rendre service à ses vacanciers !
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>

        <div className={cmS.mainComponentWrapper}>
          <h2 className={cmS.subtitle}>          
            Entre Verdon et Côte d'Azur
          </h2>
          <div className={lS.galleryContainer}>
            <div className={cmS.gridContainer}>
              {galleryOptions.map((galleryOption, index) => (
                <div className={eval(`lS.box${index + 1}`)}>
                  {documentToReactComponents(document, galleryOption)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

const staticQuery = graphql`
query {
  wpgraphql {
    accueilBy(accueilId: 359) {
      accueilAcf {
        galleryPicture01 {
          sourceUrl
          altText
          title
        }
        galleryPicture02 {
          sourceUrl
          altText
          title
        }
        galleryPicture03 {
          sourceUrl
          altText
          title
        }
        galleryPicture04 {
          sourceUrl
          altText
          title
        }
        galleryPicture05 {
          sourceUrl
          altText
          title
        }
        galleryPicture06 {
          sourceUrl
          altText
          title
        }
        galleryPicture07 {
          sourceUrl
          altText
          title
        }
        galleryPicture08 {
          sourceUrl
          altText
          title
        }
        galleryPicture09 {
          sourceUrl
          altText
          title
        }
        galleryPicture10 {
          sourceUrl
          altText
        }
        galleryPicture11 {
          sourceUrl
          altText
        }
        galleryPicture12 {
          sourceUrl
          altText
        }
        galleryPicture13 {
          sourceUrl
          altText
        }
        galleryPicture14 {
          sourceUrl
          altText
        }
        galleryPicture15 {
          sourceUrl
          altText
        }
        galleryPicture16 {
          sourceUrl
          altText
        }
        galleryPicture17 {
          sourceUrl
          altText
        }
        galleryPicture18 {
          sourceUrl
          altText
        }
      }
    }
  }
}
`