
const document = {
  nodeType: 'document',
  content: [
    {
      nodeType: 'embedded-entry-block',
      content: [
        // why do I have to use content ?
        {
          nodeType: 'text',
          value: '',
          marks: [
            {
              type: '',
            },
          ],
        },
      ],
    },
  ],
}

const galleryItemArray = [
  'galleryPicture01',
  'galleryPicture02',
  'galleryPicture03',
  'galleryPicture04',
  'galleryPicture05',
  'galleryPicture06',
  'galleryPicture07',
  'galleryPicture08',
  'galleryPicture09',
  'galleryPicture10',
  'galleryPicture11',
  'galleryPicture12',
  'galleryPicture13',
  'galleryPicture14',
  'galleryPicture15',
]

const dateStart = [
  ['dateStart1Jun', 'dateStart2Jun', 'dateStart3Jun', 'dateStart4Jun'],
  ['dateStart1Jul', 'dateStart2Jul', 'dateStart3Jul', 'dateStart4Jul'],
  ['dateStart1Aug', 'dateStart2Aug', 'dateStart3Aug', 'dateStart4Aug'],
  ['dateStart1Sep', 'dateStart2Sep', 'dateStart3Sep', 'dateStart4Sep']
]

const dateEnd = [
  ['dateEnd1Jun', 'dateEnd2Jun', 'dateEnd3Jun', 'dateEnd4Jun'],
  ['dateEnd1Jul', 'dateEnd2Jul', 'dateEnd3Jul', 'dateEnd4Jul'],
  ['dateEnd1Aug', 'dateEnd2Aug', 'dateEnd3Aug', 'dateEnd4Aug'],
  ['dateEnd1Sep', 'dateEnd2Sep', 'dateEnd3Sep', 'dateEnd4Sep']
]

const fares = [
  ['fare1Jun', 'fare2Jun', 'fare3Jun', 'fare4Jun'],
  ['fare1Jul', 'fare2Jul', 'fare3Jul', 'fare4Jul'],
  ['fare1Aug', 'fare2Aug', 'fare3Aug', 'fare4Aug'],
  ['fare1Sep', 'fare2Sep', 'fare3Sep', 'fare4Sep']
]

const statuses = [
  ['status1Jun', 'status2Jun', 'status3Jun', 'status4Jun'],
  ['status1Jul', 'status2Jul', 'status3Jul', 'status4Jul'],
  ['status1Aug', 'status2Aug', 'status3Aug', 'status4Aug'],
  ['status1Sep', 'status2Sep', 'status3Sep', 'status4Sep']
]

const periods = [
  `Mai/Juin`,
  `Juillet`,
  `Août`,
  `Septembre`
]

const titleDescr = [
  `titleDescriptionP1`,
  `titleDescriptionP2`,
  `titleDescriptionP3`,
  `titleDescriptionP4`,
]

const descr = [
  `descriptionP1`,
  `descriptionP2`,
  `descriptionP3`,
  `descriptionP4`,
]

module.exports = {
  document,
  galleryItemArray,
  dateStart,
  dateEnd,
  fares,
  statuses,
  periods,
  titleDescr,
  descr
}